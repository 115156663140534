/********************************************************************************
Filename: np-news-summary.js
Created: 05 Oct 2022
Author: Ron Robertson
Description: Displays a summary of a new element
Usage Example:
<np-news-summary></np-news-summary>
********************************************************************************/

(function() {
    const template = document.createElement('template');

    template.innerHTML = `
        <style>
            #newsSummary{
                text-decoration:none;
                color:#000000;
            }
            #visual{
              margin-bottom:20px;
              overflow:hidden;
            }
            #visual img{
              aspect-ratio: 16/9;
              width:100%;
              object-fit:cover;
              object-position:0px 0px;
            }
            #newsSummary:hover{
              cursor:pointer;
            }

            #category{
              display:inline-block;
              color:#41B6E6;
              padding-right:6px;
              font-family: var(--h4FontFamily);
              font-weight:normal;
            }
            #date{
              display:inline-block;
              padding-left:8px;
              border-left:1px solid #7e7e7e;
              color:#7e7e7e;
              font-weight:normal;
            }
            #title{
              margin: 20px 0 62px;
              font-size:var(--h4FontSize);
              font-family:var(--semiBoldFontFamily);
              font-weight:normal;
              font-size:24px;
            }

        </style>

        <div id="newsSummary" >
            <div id="visual">
                <img id="newsImg" src="" />
            </div><!--END visual-->
            <div id="copy">
                <div id="category"></div>
                <div id="date"></div>
                <div id="title"></div>
            </div><!--END copy-->
        </div><!--END newsSummary-->
    `;

    class NpNewsSummary extends HTMLElement {
        constructor() {
            /* Always call super first in constructor */
            super();

            /* bind 'this' to class in the method */
            this.summaryClickHandler = this.summaryClickHandler.bind(this);

            /* Use the Shadow DOM */
            this.attachShadow({ mode: 'open' });
            this.shadowRoot.appendChild(template.content.cloneNode(true));

            /* Reference to DOM Elements */
            this.newsSummary = this.shadowRoot.getElementById("newsSummary");
            this.visualEle = this.shadowRoot.getElementById("visual");
            this.copyEle = this.shadowRoot.getElementById("copy");
            this.imgEle = this.shadowRoot.getElementById("newsImg");
            this.categoryEle = this.shadowRoot.getElementById("category");
            this.dateEle = this.shadowRoot.getElementById("date");
            this.titleEle = this.shadowRoot.getElementById("title");

            this.monthArray=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];


        } /* END constructor */

        /* Called when added to DOM */
        connectedCallback() {

            /* Set default attribute value */
            /*
            if (!this.hasAttribute('myattribute')) {
                this.setAttribute('myattribute', "value");
            }
            */

            /* Set Event Listeners */
            this.newsSummary.addEventListener('click', this.summaryClickHandler);




        } /* END connectedCallback */

        /* Listen for when attributes change */
        /* attributes must be lowercase */
        static get observedAttributes() {
            return ['title', 'category', 'date', 'img', 'id', 'link-target'];
        }

      /* Called when attributes change */
      attributeChangedCallback(name, oldValue, newValue) {
        if(name === 'title') {
            this.titleEle.innerHTML = newValue;
            this.imgEle.setAttribute('alt',newValue);
        }
        if(name === 'category') {
            this.categoryEle.innerHTML = newValue;
        }
        if(name === 'date') {
            let dateObj = new Date(newValue+"T00:00:00");
            let month = this.monthArray[parseInt(dateObj.getMonth())]
            let year = dateObj.getFullYear();
            this.dateEle.innerHTML = month + " " + year;
        }
        if(name === 'img') {
            this.imgEle.setAttribute('src', newValue);
        }

       }/* END attributesChangedCallback */

      /* Getting attributes */
      get title() {
        return this.getAttribute('title');
      }
      get category() {
        return this.getAttribute('category');
      }
      get date() {
        return this.getAttribute('date');
      }
      get img() {
        return this.getAttribute('img');
      }
      get linkTarget() {
        return this.getAttribute('link-target');
      }

      /* Settinng attributes */
      set title(newValue) {
        this.setAttribute('title', newValue);
      }
      set category(newValue) {
        this.setAttribute('category', newValue);
      }
      set date(newValue) {
        this.setAttribute('date', newValue);
      }
      set img(newValue) {
        this.setAttribute('img', newValue);
      }
      set linkTarget(newValue) {
        this.setAttribute('link-target', newValue);
      }


      /* Called when removed from DOM */
      disconnectedCallback() {

        /* Removing event listeners */
        this.newsSummary.removeEventListener('click', this.onRouterLinkClick);

      }

      /* Method */
      //dispatches the event so other components can listen for it
      summaryClickHandler(event) {
        event.preventDefault();

        // normal link
        if(this.linkTarget){
          window.location.href = this.linkTarget;
        }else{
          // Dispatch the router event
          this.dispatchEvent(new CustomEvent('routerLinkClick',{bubbles:true,composed:true,detail:{'url':event.target.getAttribute('router-link')}}));
        }


      }


    } /* END class */

    window.customElements.define('np-news-summary', NpNewsSummary);

  })();
